// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
//TODO - FIX Turbo Link Issues
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("jquery/src/jquery")
require("bootstrap4-toggle/js/bootstrap4-toggle.min")
global.toastr = require("toastr")
require("packs/custom")
import "../stylesheets/application"
require("jquery-mousewheel/jquery.mousewheel")
require('select2')
// = require filterrific/filterrific-jquery
require('flatpickr')
require("chartkick")
require("chart.js")

const token = document.getElementsByName('csrf-token')[0].content


$(document).on('turbolinks:load', function(){
    flatpickr('.calendar',{enableTime: true,});
    flatpickr('.calendarNoTime',{});

    $('.multiselectLpSlotSelection').select2({
        ajax: {
            url: '/lp-list-slot-selection',
            data: function (params) {
                var queryParameters = {
                    filter_username: params.term
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.province + " " + obj.series + "-" + obj.number };
                    })
                };
            },
            cache: true
        }
    });

    $('.selectuser').select2({
        ajax: {
            url: '/userlist',
            maximumSelectionLength: 3,
            formatSelectionTooBig: function (limit) {
                return 'Too many selected items';
            },
            data: function (params) {
                var queryParameters = {
                    filter_username: params.term
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.username + "( " + obj.first_name + " " + obj.last_name+ ")" };
                    })
                };
            },
            cache: true
        }
    });

    $('.selectuseronlytenant').select2({
        height: 'resolve',
        ajax: {
            url: '/userlist?tenant=1',
            maximumSelectionLength: 3,
            formatSelectionTooBig: function (limit) {
                return 'Too many selected items';
            },
            data: function (params) {
                var queryParameters = {
                    filter_username: params.term
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.username + "( " + obj.first_name + " " + obj.last_name+ ")" };
                    })
                };
            },
            cache: true
        }
    });

    $('.multiselectlocation').select2({
        ajax: {
            url: '/locations.json',
            data: function (params) {
                var queryParameters = {
                    filter_name: params.term
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.name };
                    })
                };
            },
            cache: true
        }
    });

    $('.multiselectbuilding').select2({
        ajax: {
            url: '/buildings.json',
            data: function (params) {
                var queryParameters = {
                    filter_name: params.term,
                    location_id: $('.multiselectlocation').val()
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.name };
                    })
                };
            },
            cache: true
        }
    });

    $('.multiselectfloor').select2({
        ajax: {
            url: '/floors.json',
            data: function (params) {
                var queryParameters = {
                    filter_name: params.term,
                    location_id: $('.multiselectlocation').val(),
                    building_id: $('.multiselectbuilding').val()
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.name };
                    })
                };
            },
            cache: true
        }
    });

    $('.multiselectrnrs').select2({
        ajax: {
            url: '/rnrs_analytics.json',
            data: function (params) {
                var queryParameters = {
                    filter_name: params.term,
                    location_id: $('.multiselectlocation').val(),
                    floor_id: $('.multiselectfloor').val(),
                    building_id: $('.multiselectbuilding').val()
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.name };
                    })
                };
            },
            cache: true
        }
    });
    $('.multiselectgate').select2({
        ajax: {
            url: '/gates.json',
            data: function (params) {
                var queryParameters = {
                    filter_name: params.term,
                    location_id: $('.multiselectlocation').val(),
                    floor_id: $('.multiselectfloor').val(),
                    building_id: $('.multiselectbuilding').val()
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.name  };
                    })
                };
            },
            cache: true
        }
    });
    $('.selectgateentrancewithanalytic').select2({
        ajax: {
            url: '/vehicle_logs/get_entrance',
            data: function (params) {
                var queryParameters = {
                    filter_name: params.term,
                    location_id: $('.multiselectlocation').val(),
                    floor_id: $('.multiselectfloor').val(),
                    building_id: $('.multiselectbuilding').val()
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.name + " - " + obj.direction };
                    })
                };
            },
            cache: true
        }
    });
    $('.selectgateentrancefull').select2({
        ajax: {
            url: '/temporary_vehicle_authorizations/get_entrance',
            data: function (params) {
                var queryParameters = {
                    filter_name: params.term,
                    location_id: $('.multiselectlocation').val(),
                    floor_id: $('.multiselectfloor').val(),
                    building_id: $('.multiselectbuilding').val()
                }

                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return { id: obj.id, text: obj.name };
                    })
                };
            },
            cache: true
        }
    });

});

$(document).ready(function() {
    $('.multiselect').select2();
});


$(document).on('ready turbolinks:load', function (e) {
    $('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle()
});


$(document).ready(function () {
    $("#sidebarToggle").click(function () {
        $("#sidenavbar").toggleClass("sb-nav-fixed sb-sidenav-toggled");
    });
});

$(document).ready(function () {
    $(".nav-link").click(function() {
        $(this).toggleClass("active");
    });
});

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
})

let inputOptionsPromise = new Promise(function (resolve) {
    $.getJSON("/gates-arranged-for-force", function(data) {
        resolve(data)
    });
})

window.entranceExitForceVehicle = function(eeid) {
    Swal.fire({
        title: 'Please Select the Exit Gate',
        input: 'select',
        width: '800px',
        inputOptions: inputOptionsPromise,
        showCancelButton: true,
        confirmButtonText: 'Force Out',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {

            return fetch(`/vehicle-exit-force-entrance/${login}/`+ eeid)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(response.statusText)
                    }
                    return response.json()
                })
                .catch(error => {
                    Swal.showValidationMessage(
                        `Request failed: ${error}`
                    )
                })
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.value == 1){
            Swal.fire(
                'Forced!',
                'Vehicle forced successfully!',
                'success'
            )
            location.reload();
        } else {
            Swal.fire(
                'Error!',
                'Something went wrong!',
                'error'
            )
        }
    })
}

window.onButtonClickForce = function(eeid,weid) {
    const data =  { eeid: eeid, weid: weid }
    fetch('/vehicle-exit-force', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token
        },
        credentials: 'same-origin'
    }).then(function(response) {
        return response.json();
    }).then(function(data) {
        if (data == 1){
            Swal.fire(
                'Forced!',
                'Vehicle forced successfully!',
                'success'
            )
        } else {
            Swal.fire(
                'Error!',
                'Something went wrong!',
                'error'
            )
        }
    });
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
